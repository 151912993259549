<template>
    <div>
        <mu-appbar style="width: 100%;" color="primary" z-depth="1">
           {{musichouse}}
            <mu-button slot="right" flat @click="handleClick">分享</mu-button>
        </mu-appbar>

    </div>
</template>

<script>
    export default {
        name: "Navigation",
	props:{
	   musichouse:String
	},
        data: () => ({
            isOpenDrawer: false
        }),
	methods:{
		handleClick(){
            this.$emit('openShareDialog',true);
        }
	}
    }
</script>

<style scoped>

</style>
